import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { EditResult } from '@yukawa/chain-base-angular-domain';
import { map, Observable } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Registration } from './profile.model';


@Injectable({ providedIn: 'root' })
export class RegistrationService extends RestAspect
{
    private _lastResponse: Nullable<HttpResponse<EditResult>> = null;

    public constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('registrationUrl'));
    }

    public get lastResponse(): Nullable<HttpResponse<unknown>>
    {
        return this._lastResponse;
    }

    register(email: string): Observable<EditResult>
    {
        const url = this.formatServiceUrl('/create?email=' + email);
        return this.http.post<EditResult>(
            url,
            {},
            {
                observe: 'response',
            },
        ).pipe(
            map((response) =>
            {
                this._lastResponse = response;
                return response.body as EditResult;
            }),
        );
    }

    registerConfirm(registration: Registration, token: string): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/confirm'), registration, {
            observe: 'response',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            }),
        }).pipe(
            map((response) =>
            {
                this._lastResponse = response;
                return response.body as EditResult;
            }),
        );
    }
}
