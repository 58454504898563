import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Address, Change, Info } from '@yukawa/chain-base-angular-domain';
import { map } from 'rxjs';
import { PlainObject, StringKeys } from 'simplytyped';
import { Company } from '../company';
import { RegionService } from '../region';
import { Region } from '../region/region.entity';
import { StoreDatasource } from './store-datasource.service';
import { Store } from './store.entity';
import { Store as IStore } from './store.model';
import { CommonService, Country } from '@hemro/lib/shared';
import { HemroRoles, StoreRoles, UserService } from '@hemro/lib/profile';


export class StoreTableEntry extends QueryTableEntry<Store, IStore>
{
    static viewConfig: IStore = {
        storeId  : 0,
        info     : {
            id       : '',
            name     : '',
            shortName: '',
            desc     : '',
        },
        company  : {} as Company,
        region   : {} as Region,
        regionId : 0,
        companyId: 0,
        created  : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change   : {
            date : new Date(),
            notes: '',
            user : '',
        },
        address  : {
            street     : '',
            houseNumber: '',
            zipCode    : '',
            city       : '',
            countryCode: '',
        },
    };

    public constructor(
        store: IStore = StoreTableEntry.viewConfig,
    )
    {
        super(
            store instanceof Store ? store : new Store(store),
            store?.storeId,
            store?.info?.name,
        );
    }

    public get viewConfig(): IStore
    {
        return StoreTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'STORE.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = IStore>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'Store';
        const readonlyStoreDetails = !AppInjector.get(UserService).hasRole(...StoreRoles.editStoreDetails);
        const canEditAllCompanies = AppInjector.get(UserService).hasRole(HemroRoles.editAllCompanies);
        const companyColumnDisplayed = AppInjector.get(StoreDatasource).companyColumnDisplayed;

        switch ((key as StringKeys<IStore> | StringKeys<Info> | StringKeys<Address>)) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                    showInTable: false
                });
                return;
            case 'companyId':
                type                 = 'select';
                detail.showInDetails = false;
                detail.showInTable   = false;
                break;
            case 'company':
                type                 = 'text';
                value                = item?.['company']?.info?.name;
                detail.showInTable   = canEditAllCompanies && companyColumnDisplayed;
                break;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                    showInTable: false
                });
                return;
            case 'desc':
                type = 'multiline';
                break;
            case 'info':
                detail.entityName = 'Info';
                this.mapDetails<Info>(details, value, 'name', {
                    ...detail,
                    group       : key,
                    groupIndex  : 1,
                    groupByField: true,
                    class       : 'h4',
                    tableGroup  : true,
                    canEdit     : true,
                    required    : true,
                    readonly    : readonlyStoreDetails
                });
                this.mapDetails<Info>(details, value, 'shortName', {
                    ...detail,
                    group      : key,
                    groupIndex : 1,
                    tableGroup : false,
                    showInTable: false,
                    canEdit    : true,
                    required   : false,
                    readonly   : readonlyStoreDetails
                });
                this.mapDetails<Info>(details, value, 'desc', {
                    ...detail,
                    group          : key,
                    groupIndex     : 1,
                    tableGroup     : true,
                    canEdit        : true,
                    required       : false,
                    markdownSupport: true,
                    readonly       : readonlyStoreDetails
                });
                return;
            case 'region':
                type                = 'select';
                detail.required     = true;
                detail.canEdit      = true;
                detail.readonly     = readonlyStoreDetails;
                detail.groupByField = 'region.info.name';
                detail.label        = 'STORE.REGION.INFO.NAME';
                detail.compareWith  = (o1: Region | number, o2: Region | number): boolean =>
                {
                    const id1 = typeof o1 === 'number' ? o1 : o1?.regionId;
                    const id2 = typeof o2 === 'number' ? o2 : o2?.regionId;

                    return id1 === id2;
                };
                detail.options$     = AppInjector.get(RegionService).queryRegion({})
                    .pipe(map(queryResult =>
                        queryResult.items.map(_item => ({
                            name : _item.info.name,
                            value: _item,
                        }))));
                break;
            case 'regionId':
                type                 = 'number';
                detail.showInDetails = false;
                detail.showInTable   = false;
                break;
            case 'address':
                detail.entityName  = 'Address';
                detail.showInTable = false;
                this.mapDetails<Address>(details, value, 'street', {
                    ...detail,
                    group       : key,
                    required    : true,
                    groupIndex  : 2,
                    class       : 'h4',
                    groupByField: true,
                    tableGroup  : true,
                    canEdit     : true,
                    readonly    : readonlyStoreDetails,
                });
                this.mapDetails<Address>(details, value, 'houseNumber', {
                    ...detail,
                    group      : key,
                    required    : true,
                    groupIndex : 2,
                    tableGroup : false,
                    showInTable: false,
                    canEdit    : true,
                    readonly   : readonlyStoreDetails

                });
                this.mapDetails<Address>(details, value, 'zipCode', {
                    ...detail,
                    type        : 'number',
                    group       : key,
                    required    : true,
                    groupIndex  : 2,
                    groupByField: true,
                    tableGroup  : true,
                    canEdit     : true,
                    readonly    : readonlyStoreDetails,
                    showInTable : true
                });
                this.mapDetails<Address>(details, value, 'city', {
                    ...detail,
                    group      : key,
                    required    : true,
                    groupIndex : 2,
                    tableGroup : false,
                    canEdit    : true,
                    readonly   : readonlyStoreDetails,
                    showInTable : true
                });
                this.mapDetails<Address>(details, value, 'countryCode', {
                    ...detail,
                    group       : key,
                    groupIndex  : 2,
                    canEdit     : true,
                    readonly    : readonlyStoreDetails,
                    showInTable : true
                });
                return;
            case 'countryCode':
                type                = 'select';
                detail.canEdit      = true;
                detail.readonly     = readonlyStoreDetails;
                detail.required     = true;
                detail.label        = 'STORE.ADDRESS.COUNTRYCODE';
                detail.compareWith  = (o1: Country | string, o2: Country | string): boolean =>
                {
                    const id1 = typeof o1 === 'string' ? o1 : o1?.code;
                    const id2 = typeof o2 === 'string' ? o2 : o2?.code;

                    return id1 === id2;
                };
                detail.options$      = AppInjector.get(CommonService).loadCountries()
                    .pipe(map(countries =>
                        countries.map(_item => ({
                            name : _item.name,
                            value: _item.code,
                        }))));
                value                = value || '-';
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: detail.label || this.formatKey(level),
            value,
            options,
        }));
    }
}
