import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppInjector, ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { EditResult, QueryResult } from '@yukawa/chain-base-angular-domain';
import { User as IUser, UserFilter } from '@yukawa/chain-main-angular-core';
import {
    Session,
    SessionChangedEventArgs,
    SessionService,
    SessionStoreService,
} from '@yukawa/chain-main-angular-session';
import { Credentials } from '@yukawa/chain-security-angular-core/chain/security/gen/chain-security-angular-domain';
import { delay, map, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { Nullable } from 'simplytyped';
import { HemroRoles, User } from './user.model';


@Injectable()
export class UserService extends RestAspect
{
    active: boolean                              = false;
    private _user: ReplaySubject<Nullable<User>> = new ReplaySubject<Nullable<User>>(1);

    constructor(
        http: HttpClient,
        configService: ConfigService,
        private readonly _sessionService: SessionService,
        private readonly _sessionStoreService: SessionStoreService,
    )
    {
        super(http, configService, configService.formatUrl('userUrl'));

        this._user.next(this.user as User);
        _sessionService.sessionChanged.subscribe(async (session: Session, ea?: SessionChangedEventArgs) =>
        {
            if (ea?.session instanceof Session.SessionConnected) {
                this._user.next(this.user as User);
            }
            else {
                this._user.next(null);
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns the current session user. (use in apps)
     */
    get user(): Nullable<User>
    {
        return this._sessionStoreService.getJSON('user');
    }

    /**
     * Returns the user retrieved by {@see UserService.get()} (use in admin)
     */
    get user$(): Observable<Nullable<User>>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    queryUser(filter: UserFilter): Observable<QueryResult<User>>
    {
        return this.query<any>(this.formatServiceUrl('/query'), filter);
    }

    loadUser(username: string): Observable<User>
    {
        //return this.http.get<any>(this.formatServiceUrl(`/${username}`));
        return this.queryUser({
            username,
            withGroupContexts: true,
        }).pipe(map(queryResult => queryResult.items?.[0] as User));
    }

    deleteUser(username: string): Observable<User>
    {
        return this.http.delete<any>(this.formatServiceUrl(`/${username}`));
    }

    saveUser(user: User, method: 'put' | 'post' = 'post'): Observable<User>
    {
        return this.http[method]<EditResult>(this.formatServiceUrl(), user).pipe(
            // delay by 1 second to allow the user to be created
            delay(1000),
            switchMap(() => this.loadUser(user.username)),
        );
    }

    updateUser(user: User): Observable<User>
    {
        return this.saveUser(user, 'put');
    }

    merge(user: IUser): Observable<User>
    {
        return this.http.post<User>(this.formatServiceUrl('/merge'), user)
            .pipe(switchMap(() => this.loadUser(user.username as string)));
    }

    changePassword(credentials: Credentials): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/password/change'), credentials);
    }

    selfChangePassword(credentials: Credentials & { oldPassword: string }): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/password/self-change'), credentials);
    }

    resetPassword(email: string): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/password/self-reset'), {
            username: email,
        });
    }

    resetPasswordConfirm(password: string, token: string): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/password/self-reset-confirm'), {
            password,
        }, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            }),
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ UserService.user$ Members
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        return this.http.get<User>(this.formatServiceUrl()).pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this.http.patch<User>(this.formatServiceUrl(), { user }).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }

    hasRole(...roles: Array<HemroRoles>): boolean
    {
        const sessionService = AppInjector.get(SessionService);
        // Pass always for super admin
        return !!sessionService.session?.user?.hasRole(HemroRoles.admin) ||
            roles.some(role => !!sessionService.session?.user?.hasRole(role));
    }
}
