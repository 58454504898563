import { Injectable } from '@angular/core';
import { ChatService } from './chat.service';
import { BehaviorSubject, catchError, map, Observable, of, Subject, switchMap, tap, withLatestFrom } from 'rxjs';
import { ChatRequest, MessageBody } from './message-body.model';

@Injectable()
export class ChatFacadeService {
    private _sessionId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private _messages: BehaviorSubject<MessageBody[]> = new BehaviorSubject<MessageBody[]>([]);
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _isTyping: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _stopTyping: Subject<number> = new Subject();
    private _message = new Subject<string>();
    private _abortController: AbortController | null = null;

    constructor(private _chatService: ChatService) {
        this._initMessageQueue();
    }

    get sessionId$(): Observable<string | null> {
        return this._sessionId.asObservable();
    }

    get messages$(): Observable<MessageBody[]> {
        return this._messages.asObservable();
    }

    get isTyping$(): Observable<boolean> {
        return this._isTyping.asObservable();
    }

    get isNewSession$(): Observable<boolean> {
        return this.sessionId$.pipe(
            map(sessionId => !sessionId)
        );
    }

    get isEmpty$(): Observable<boolean> {
        return this.messages$.pipe(
            map(messages => !messages.length)
        );
    }

    get message$(): Observable<string> {
        return this._message.asObservable();
    }

    get stopTyping$(): Observable<number> {
        return this._stopTyping.asObservable();
    }

    get loading$(): Observable<boolean> {
        return this._loading.asObservable();
    }

    get isTyping(): boolean {
        return this._isTyping.value;
    }

    set isTyping(value: boolean) {
        this._isTyping.next(value);
    }

    sendMessage(message: string): void {
        this._message.next(message);
    }

    resetChat(): void {
        this._messages.next([]);
        this._sessionId.next(null);
    }

    stopTyping(): void {
        this._stopTyping.next(new Date().getTime());
        this.cancelRequest();
    }

    private _putMessageToList(message: MessageBody): void {
        this._messages.next([...this._messages.getValue(), message]);
    }

    private _initMessageQueue(): void {
        this.message$
            .pipe(
                withLatestFrom(this.sessionId$),
                switchMap(([message, sessionId]) => {
                    this._putMessageToList({
                        payload: message,
                        timeStamp: new Date().toISOString(),
                        isResponse: false,
                    });
                    const request: ChatRequest = {
                        sessionId,
                        payload: message
                    };
                    this._loading.next(true);
                    this._abortController = new AbortController();
                    return this._chatService.ask(request, this._abortController.signal)
                        .pipe(
                            tap({
                                next: () => this._loading.next(false),
                                error: () => this._loading.next(false)
                            }),
                            catchError((error) => {
                                this._putMessageToList({
                                    payload: error
                                        ? 'Canceled.'
                                        : 'Sorry, I could not understand your request. Please try again.',
                                    timeStamp: new Date().toISOString(),
                                    isResponse: true,
                                    isTyping: false
                                });
                                return of(null);
                            })
                        );
                })
            )
            .subscribe({
                next: (response) => {
                    if (response) {
                        this._sessionId.next(response.sessionId as string);
                        this._putMessageToList({
                            ...response,
                            isResponse: true,
                            isTyping: true
                        });
                    }
                }
            });
    }

    private cancelRequest(): void {
        if (this._abortController) {
            this._abortController.abort();
            this._loading.next(false);
        }
    }
}
