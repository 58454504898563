import { CloudRecipe as ICloudRecipe } from './cloud-recipe.model';
import { Entity } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { CloudRecipeStatus, RecipeMode } from '@hemro/lib/domain';

export class CloudRecipe extends Entity<ICloudRecipe> implements ICloudRecipe {
    public bean?: string;
    public brewRatio?: number;
    public brewTemperature?: number;
    public brewTime?: number;
    public brewWeight?: number;
    public cloudRecipeId?: number;
    public coarseness?: number;
    public companyId?: number;
    public grindTime?: number;
    public grindWeight?: number;
    public icon?: string;
    public mode?: RecipeMode;
    public model!: string;
    public name!: string;
    public status?: CloudRecipeStatus;
    public type?: string;
    public change!: Required<Change>;
    public created!: Required<Change>;

    public constructor(initialData: ICloudRecipe) {
        super(initialData);
    }

    public get key(): string {
        return String(this.cloudRecipeId);
    }

    public override toJson(): ICloudRecipe {
        return this.toJsonWithKeys(
            [
                'brewTemperature',
                'brewTime',
                'brewWeight',
                'cloudRecipeId',
                'coarseness',
                'companyId',
                'grindTime',
                'grindWeight',
                'icon',
                'mode',
                'model',
                'name',
                'status',
                'type',
                'bean',
                'brewRatio',
                'change',
                'created',
            ],
            {},
        );
    }

    public override updateFromJson(data: ICloudRecipe): void {
        this.setFromJson(data, [
            'brewTemperature',
            'brewTime',
            'brewWeight',
            'cloudRecipeId',
            'coarseness',
            'companyId',
            'grindTime',
            'grindWeight',
            'model',
            'name',
            'status',
            'bean',
            'type',
            'icon',
            'mode',
            'brewRatio',
            'change',
            'created',
        ], undefined, {
            created: (created: Change) => {
                if (created) {
                    created.date = new Date(created.date as never);
                }
                return created;
            },
            change: (change: Change) => {
                if (change) {
                    change.date = new Date(change.date as never);
                }
                return change;
            },
        });
    }

    public override toString(): string {
        return this.name;
    }
}
