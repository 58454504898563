import { Injectable } from '@angular/core';
import { ProfileFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { ProfileTableEntry } from './profile-table-entry.model';
import { Profile } from './profile.entity';
import { IProfile } from './profile.model';
import { ProfileService } from './profile.service';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root',
})
export class ProfileDatasource extends QueryTableDatasource<Profile, IProfile, ProfileTableEntry, ProfileFilter>
{
    constructor()
    {
        super(ProfileTableEntry, {
            loadGroups: true,
        });
    }

    public async read(filter?: ProfileFilter): Promise<Array<Profile>>
    {
        this.query = await firstValueFrom(AppInjector.get(ProfileService).query({
            ...this.defaultFilter,
            ...filter,
        }));
        return this.query.items;
    }

    public async create(entry: ProfileTableEntry): Promise<ProfileTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(ProfileService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: ProfileTableEntry, profile?: Partial<Profile>): Promise<ProfileTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Profile file ${entry.id} does not exist.`);
        }

        if (profile) {
            await lastValueFrom(AppInjector.get(ProfileService).merge(new Profile({
                ...entry.entity.toJson(),
                ...profile,
            })));

            const loadObservable = AppInjector.get(ProfileService).load(entry.id);
            entry                = this.newEntry(await firstValueFrom(loadObservable));

            // Update password
            const credentials = profile.account?.credentials;
            if (profile.account?.credentials) {
                await lastValueFrom(AppInjector.get(UserService).selfChangePassword(profile.account.credentials as never));
            }
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: ProfileTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Profile ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(ProfileService).unregister(entry.entity.user.username));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(profileId: string): Promise<ProfileTableEntry>
    {
        const profileService = AppInjector.get(ProfileService);
        return this.newEntry(
            profileService.repository.has(profileId)
                ? profileService.repository.get(profileId) as Profile
                : (await firstValueFrom(profileService.load(profileId).pipe(map((group) =>
                    {
                        if (!group) {
                            throw new QueryTableDatasourceException(`Profile ${profileId} does not exist.`);
                        }
                        return group;
                    }),
                ))));
    }
}
