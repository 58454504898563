import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '@hemro/lib/admin';
import { NotificationType } from '@hemro/lib/domain';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {
    opened = false;

    private _notification: Notification;

    constructor(private _translocoService: TranslocoService) {
    }

    @Input()
    set notification(notification: Notification) {
        this._notification = { ...notification };
    }

    @Output() markAsRead = new EventEmitter();

    get notification(): Notification {
        return this._notification;
    }

    get notificationContent(): string {
        if (!this.notification) {
            return null;
        }
        let text: string;
        switch (this.notification.type) {
            case NotificationType.FIRMWARE_RELEASED:
                text = this._translocoService.translate(
                    'NOTIFICATION.CONTENT.FIRMWARE_RELEASED',
                    { version: this.notification.relatedFirmware.versionText }
                );
                break;
            case NotificationType.BURR_LIFE_WARN:
                text = this._translocoService.translate(
                    'NOTIFICATION.CONTENT.BURR_LIFE_WARN',
                    { deviceId: this.notification.relatedDevice.deviceId }
                );
                break;
            case NotificationType.BURR_LIFE_REACHED:
                text = this._translocoService.translate(
                    'NOTIFICATION.CONTENT.BURR_LIFE_REACHED',
                    { deviceId: this.notification.relatedDevice.deviceId }
                );
                break;
            case NotificationType.GRINDER_ALERT:
                text = this._translocoService.translate(
                    'NOTIFICATION.CONTENT.GRINDER_ALERT',
                    {
                        deviceId: this.notification.relatedDevice.deviceId,
                        title: this.notification.details.translationHeader
                    }
                );
                break;
        }
        return text;
    }

    get notificationDesc(): string {
        if (!this.notification) {
            return '';
        }
        let text: string;
        switch (this.notification.type) {
            case NotificationType.BURR_LIFE_WARN:
            case NotificationType.BURR_LIFE_REACHED:
                return '';
            case NotificationType.FIRMWARE_RELEASED:
                text = this.notification.relatedFirmware?.notes
                    ?.split('\n')
                    ?.map(note => `<li>${note}</li>`)
                    ?.join('');
                return `${text}`;
            case NotificationType.GRINDER_ALERT:
                const desc = this.notification.details.translationDescription
                    || this.notification.details.translation
                    || '';
                text = (desc as string).replace(/\n/g, '<br/>');
                return `<br/>Description: ${text}`;
        }
    }

    markRead(notificationId: number): void {
        this.notification.read = true;
        this.markAsRead.emit(notificationId);
    }

}
