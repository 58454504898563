import { Injectable } from '@angular/core';
import { CloudRecipeSlotFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { lastValueFrom } from 'rxjs';
import { CloudRecipeSlotTableEntry } from './cloud-recipe-slot-table-entry.model';
import { CloudRecipeSlot as ICloudRecipeSlot } from './cloud-recipe-slot.model';
import { CloudRecipeSlot } from './cloud-recipe-slot.entity';
import { CloudRecipeSlotService } from './cloud-recipe-slot.service';


@Injectable({
    providedIn: 'root',
})
export class CloudRecipeSlotDatasource extends QueryTableDatasource<CloudRecipeSlot, ICloudRecipeSlot, CloudRecipeSlotTableEntry, CloudRecipeSlotFilter>
{
    constructor()
    {
        super(CloudRecipeSlotTableEntry);
    }

    public async read(filter?: CloudRecipeSlotFilter): Promise<Array<CloudRecipeSlot>>
    {
        this.query = await lastValueFrom(AppInjector.get(CloudRecipeSlotService).queryRecipeSlots({
            ...this.defaultFilter,
            ...filter,
        }));
        return Array.from({ length: 6 }, (_, i) => i + 1).map((slot) => {
            const recipeSlot = this.query.items.find(x => x.slot === slot);
            return recipeSlot ?? {
                slot,
                slotId: slot,
                recipe: {
                    name: ' ',
                }
            } as CloudRecipeSlot;
        });
    }

    public async create(entry: CloudRecipeSlotTableEntry): Promise<CloudRecipeSlotTableEntry>
    {
        const newEntry = this.newEntry(await lastValueFrom(AppInjector.get(CloudRecipeSlotService).create({ ...entry.entity })));
        this.entries.splice(0, 0, newEntry);
        return await this.update(newEntry);
    }

    public async update(entry: CloudRecipeSlotTableEntry, firmware?: Partial<CloudRecipeSlot>): Promise<CloudRecipeSlotTableEntry>
    {
        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: CloudRecipeSlotTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Cloud recipe slot ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(CloudRecipeSlotService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(slotId: string): Promise<CloudRecipeSlotTableEntry>
    {
        throw new Error('Method not implemented.');
    }
}
