import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { AutoComplete } from '@yukawa/chain-base-angular-comp/shared';
import { Address, Change, Info } from '@yukawa/chain-base-angular-domain';
import { PlainObject, StringKeys } from 'simplytyped';
import { Company } from './company.model';


export class CompanyTableEntry extends QueryTableEntry<Company>
{
    static viewConfig: Company = {
        companyId: 0,
        info     : {
            id       : '',
            name     : '',
            shortName: '',
            desc     : '',
        },
        created  : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change   : {
            date : new Date(),
            notes: '',
            user : '',
        },
        address  : {
            street     : '',
            houseNumber: '',
            zipCode    : '',
            city       : '',
            countryCode: '',
        },
    };

    public constructor(
        company: Company = CompanyTableEntry.viewConfig,
    )
    {
        super(company, company?.companyId, company?.info?.name);

        if (company.created) {
            company.created.date = new Date(company.created.date);
        }
        if (company.change) {
            company.change.date = new Date(company.change.date);
        }
        if (!company.address) {
            company.address = CompanyTableEntry.viewConfig.address;
        }
    }

    public get viewConfig(): Company
    {
        return CompanyTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'COMPANY.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = Company>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'Company';

        switch ((key as StringKeys<Company> | StringKeys<Info>)) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'desc':
                type = 'multiline';
                break;
            case 'info':
                detail.entityName = 'Info';
                this.mapDetails<Info>(details, value, 'name', {
                    ...detail,
                    group       : key,
                    groupIndex  : 1,
                    groupByField: true,
                    class       : 'h4',
                    tableGroup  : true,
                    canEdit     : true,
                    required    : true,
                });
                this.mapDetails<Info>(details, value, 'shortName', {
                    ...detail,
                    group      : key,
                    groupIndex : 1,
                    tableGroup : false,
                    showInTable: false,
                    canEdit    : true,
                    required   : false,
                });
                this.mapDetails<Info>(details, value, 'desc', {
                    ...detail,
                    group          : key,
                    groupIndex     : 1,
                    tableGroup     : true,
                    canEdit        : true,
                    required       : false,
                    markdownSupport: true,
                });
                return;
            case 'address':
                detail.entityName  = 'Address';
                detail.showInTable = false;
                this.mapDetails<Address>(details, value, 'street', {
                    ...detail,
                    group       : key,
                    groupIndex  : 2,
                    class       : 'h4',
                    groupByField: true,
                    tableGroup  : true,
                    canEdit     : true,
                });
                this.mapDetails<Address>(details, value, 'houseNumber', {
                    ...detail,
                    group      : key,
                    groupIndex : 2,
                    tableGroup : false,
                    showInTable: false,
                    canEdit    : true,
                });
                this.mapDetails<Address>(details, value, 'zipCode', {
                    ...detail,
                    type        : 'number',
                    group       : key,
                    groupIndex  : 2,
                    groupByField: true,
                    class       : 'h4',
                    tableGroup  : true,
                    canEdit     : true,
                });
                this.mapDetails<Address>(details, value, 'city', {
                    ...detail,
                    group      : key,
                    groupIndex : 2,
                    tableGroup : false,
                    showInTable: false,
                    canEdit    : true,
                });
                this.mapDetails<Address>(details, value, 'countryCode', {
                    ...detail,
                    group       : key,
                    groupIndex  : 2,
                    class       : 'h4',
                    tableGroup  : true,
                    canEdit     : true,
                    autocomplete: AutoComplete.Country,
                    maxLength   : 3,
                });
                return;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
